var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "CCard",
        [
          _c("CCardHeader", [_vm._v("Der Gutshof")]),
          _c(
            "CCardBody",
            [
              _c(
                "CButton",
                {
                  attrs: {
                    color: "primary",
                    disabled: _vm.importGutshofOrdersIsLoading
                  },
                  on: { click: _vm.importGutshofOrders }
                },
                [_vm._v(" Import Users/Orders ")]
              ),
              _c(
                "CButton",
                {
                  attrs: {
                    color: "danger",
                    disabled: _vm.deleteGutshofOrdersIsLoading
                  },
                  on: { click: _vm.deleteGutshofOrders }
                },
                [_vm._v(" Delete Users/Orders ")]
              ),
              _c("br"),
              _c(
                "CButton",
                {
                  attrs: { color: "primary" },
                  on: { click: _vm.importGutshofVouchers }
                },
                [_vm._v("Import Vouchers")]
              ),
              _c(
                "CButton",
                {
                  attrs: { color: "danger" },
                  on: { click: _vm.deleteGutshofVouchers }
                },
                [_vm._v("Delete Vouchers")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }